<div class="wrap">
    <img
        class="close"
        (click)="close()"
        src="assets/img/close.svg"
        alt="close">
    <app-flow
        [flow]="flow"
        [isSkipFirstStep]="isInit"
        [step]="step" />
</div>
